import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import styles from './TaskDetails.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  makePlatformActionsSelector,
  makeSelectGetSocialAccountSuggestionsPending,
  makeSelectSocialAccountSuggestions,
  makeTasksSelector,
} from 'containers/NewExperienceCampaignBuilderPage/selectors';
import { ReactComponent as ArrouwDownIcon } from 'assets/images/arrow_down.svg';
import Tooltip from 'components/Tooltip';
import { CAMPAIGN_TASKS_CATEGORIES } from '../../../constants';
import Input from '../Input';
import { ReactComponent as DeleteBlackIcon } from 'assets/images/black_close_icon.svg';
import { ReactComponent as SyncIcon } from 'assets/images/sync.svg';
import LoadingSpinner from 'components/Layout/LoadingSpinner';
import { ReactComponent as VisitIcon } from 'assets/images/visit.svg';
import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close1.svg';
import { ReactComponent as DuplicateIcon } from 'assets/images/duplicate.svg';
import { ReactComponent as DeleteTaskIcon } from 'assets/images/remove_circle_outline.svg';
import classNames from 'classnames';
import {
  addDuplicateTask,
  aiRegenerateTaskDescriptionPlusSocialMediaUrlsRequest,
  deleteTask,
  fetchOpenAiSocialAccountSuggestionsRequest,
} from 'containers/NewExperienceCampaignBuilderPage/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RadioButton from '../RadioButton';

export const PLATFORM_MAPPER = {
  EYCROWD: 'EyCrowd',
  FB: 'Facebook',
  IG: 'Instagram',
  X: 'Twitter',
  TW: 'Twitter',
  TT: 'Tik-Tok',
  LI: 'LinkedIn',
  YELP: 'Yelp',
  AMAZON: 'Amazon',
  HTTP3: 'Website',
};

const SOCIAL_MEDIA_MAPPER = {
  FB: 'facebook',
  IG: 'instagram',
  X: 'twitter',
  LI: 'linkedIn',
  TT: 'tiktok',
};

export const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ' hour(s) and ' + rminutes + ' minute(s)';
};

const formSchema = {
  description: yup.string().trim().required('Description is required'),
  pv: yup.object().shape({
    url_required: yup.boolean(),
  }),
  url: yup.string().when('pv.url_required', {
    is: true,
    then: yup.string().trim().required('URL field is required'),
  }),
  options: yup
    .array()
    .when(
      [
        'pv.platform_action',
        'pv.platform_item',
        'pv.basic_verification_method',
      ],
      {
        is: (platform_action, platform_item, basic_verification_method) =>
          (platform_action &&
            platform_action.toLowerCase() ===
              CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
            ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(platform_item)) ||
          ['SURVEY-MULTIPLE', 'SURVEY-SINGLE'].includes(
            basic_verification_method
          ),
        then: yup
          .array()
          .of(
            yup.object().shape({
              text: yup.string().trim().required('Option text is required'),
            })
          )
          .min(2, 'At least two options are required'),
      }
    ),
};

const schema = yup.object({
  tasks: yup.array().of(yup.object().shape(formSchema)),
});

const TaskManager = forwardRef(
  (
    {
      selectedObjectives,
      setSelectedObjectives,
      tasksInfo,
      setTasksInfo,
      taskDescriptions,
      brandWebsite,
      brandStory,
      storyBrandFormData,
      setStep,
      inPerson,
      importedCampaign,
    },
    ref
  ) => {
    const {
      register,
      control,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
      watch,
    } = useForm({
      defaultValues: {
        tasks: [
          {
            description: '',
            descriptions: [],
            taskId: 0,
            objectiveId: 0,
            objectiveHeadline: '',
            mentions: '',
            hashtags: [],
            url: '',
            pv: {
              platform_action: '',
            },
            inPersonTask: false,
            options: [{ text: '', isOther: false }],
          },
        ],
      },
      delayError: 300,
      resolver: yupResolver(schema),
    });
    const tasks = useSelector(makeTasksSelector());
    const platformAction = useSelector(makePlatformActionsSelector());
    const getSocialAccountSuggestionsPending = useSelector(
      makeSelectGetSocialAccountSuggestionsPending()
    );
    const socialAccountSuggestions = useSelector(
      makeSelectSocialAccountSuggestions()
    );
    const [loadingStates, setLoadingStates] = useState({});
    const dispatch = useDispatch();

    const { fields, remove, move, insert } = useFieldArray({
      control,
      name: 'tasks',
    });

    useImperativeHandle(ref, () => ({
      handleFormSubmit() {
        handleSubmit(onSubmit)();
      },
      onBack() {
        onBackClick();
      },
    }));

    const handleClearInputForField = (fieldName) => {
      setValue(fieldName, '');
    };

    const onSubmit = () => {
      const tasks = getValues('tasks');

      for (const task of tasks) {
        setTasksInfo((prev) => ({
          ...prev,
          [`${task.objectiveId}-${task.taskId}`]: {
            ...prev[`${task.objectiveId}-${task.taskId}`],
            ...task,
          },
        }));
      }
      setStep((prev) => prev + 1);
    };

    const saveTasks = useCallback(() => {
      const tasks = getValues('tasks');

      if (getSocialAccountSuggestionsPending) return;

      for (const task of tasks) {
        setTasksInfo((prev) => ({
          ...prev,
          [`${task.objectiveId}-${task.taskId}`]: {
            ...prev[`${task.objectiveId}-${task.taskId}`],
            ...task,
          },
        }));
      }
    }, [getValues, setTasksInfo, getSocialAccountSuggestionsPending]);

    useEffect(() => {
      const intervalId = setInterval(() => {
        saveTasks();
      }, 3000);

      return () => clearInterval(intervalId);
    }, [saveTasks]);

    const onBackClick = () => {
      const tasks = getValues('tasks');

      for (const task of tasks) {
        setTasksInfo((prev) => ({
          ...prev,
          [`${task.objectiveId}-${task.taskId}`]: {
            ...prev[`${task.objectiveId}-${task.taskId}`],
            ...task,
          },
        }));
      }
    };

    const descriptionValues = fields.map((field, index) =>
      watch(
        `tasks[${index}].description`,
        field.description ?? field.descriptions[0].label
      )
    );

    const urlValues = fields.map((field, index) =>
      watch(`tasks[${index}].url`, field.url)
    );

    const hashtagValues = fields.map((field, index) =>
      watch(`tasks[${index}].hashtags`, field.hashtags)
    );

    const mentionValues = fields.map((field, index) =>
      watch(`tasks[${index}].mentions`, field.mentions)
    );

    const optionTextValues = fields.map((field, index) =>
      field?.options
        ? field.options.map((_, optionIndex) =>
            watch(`tasks[${index}].options[${optionIndex}].text`, '')
          )
        : []
    );

    const onRegenerateClick = (
      combinedTaskId,
      descriptions,
      fieldId,
      index
    ) => {
      const [objectiveId, taskId] = combinedTaskId.split('-');
      const tasks = getValues('tasks');

      const task = tasks.find(
        (task) => task.id == taskId && task.objectiveId == objectiveId
      );

      if (task) {
        const { taskId, objectiveId, objectiveHeadline, name } = task;

        let pv;
        if (task.originalId) {
          pv = platformAction.find((p) => p.taskId === task.originalId);
        } else {
          pv = platformAction.find((p) => p.taskId === task.id);
        }

        const objectiveTask = {
          taskId: taskId,
          objectiveId,
          objectiveHeadline,
          taskName: name,
          taskDescriptions: descriptions.map(
            (description) => description.label
          ),
        };
        setLoadingStates((prevState) => ({
          ...prevState,
          [`${objectiveId}-${fieldId}`]: true,
        }));
        dispatch(
          aiRegenerateTaskDescriptionPlusSocialMediaUrlsRequest(
            {
              objectiveTask,
              brandStory,
              brandName: storyBrandFormData.businessName,
            },
            (result) => {
              let updatedTasks = [...tasks];

              if (
                updatedTasks[index].taskId === taskId &&
                updatedTasks[index].objectiveId === objectiveId
              ) {
                updatedTasks[index] = {
                  ...updatedTasks[index],
                  description: result.tasks[0].task_description,
                };

                if (!!pv.url_required) {
                  if (
                    result.socialMediaUrls[SOCIAL_MEDIA_MAPPER[pv.platform]]
                  ) {
                    updatedTasks[index].url =
                      result.socialMediaUrls[SOCIAL_MEDIA_MAPPER[pv.platform]];
                  } else {
                    updatedTasks[index].url = brandWebsite;
                  }
                }

                if (!!+pv.hashtags_required) {
                  updatedTasks[index].hashtags = result.hashtags;
                }

                if (!!+pv.mentions_required) {
                  updatedTasks[index].mentions = result.mentions;
                }
              }

              setValue('tasks', updatedTasks);

              setLoadingStates((prevState) => ({
                ...prevState,
                [`${objectiveId}-${fieldId}`]: false,
              }));
            },
            () => {
              setLoadingStates((prevState) => ({
                ...prevState,
                [`${objectiveId}-${fieldId}`]: false,
              }));

              let updatedTasks = [...tasks];

              if (
                updatedTasks[index].taskId === taskId &&
                updatedTasks[index].objectiveId === objectiveId
              ) {
                let newDescriptionIndex;

                if (updatedTasks[index].description) {
                  const currentDescriptionIndex = descriptions.findIndex(
                    (d) => d.label === updatedTasks[index].description
                  );

                  newDescriptionIndex =
                    currentDescriptionIndex === descriptions.length - 1
                      ? 0
                      : currentDescriptionIndex + 1;
                } else {
                  newDescriptionIndex = descriptions.length > 1 ? 1 : 0;
                }

                updatedTasks[index].description =
                  descriptions[newDescriptionIndex].label;
              }

              setValue('tasks', updatedTasks);
            }
          )
        );
      }
    };

    useEffect(() => {
      if (brandWebsite && !socialAccountSuggestions && !importedCampaign) {
        dispatch(
          fetchOpenAiSocialAccountSuggestionsRequest({ url: brandWebsite })
        );
      }
    }, [brandWebsite, dispatch, socialAccountSuggestions, importedCampaign]);

    const canDeleteTask = (field) => {
      const isDuplicate = !!field.originalId;

      if (isDuplicate) {
        const duplicateOrOriginalExists = fields.some(
          (f) =>
            (f.taskId === field.originalId &&
              !f.originalId &&
              f.objectiveId === field.objectiveId) ||
            (f.originalId === field.originalId &&
              f.id !== field.id &&
              f.objectiveId === field.objectiveId)
        );
        return duplicateOrOriginalExists;
      } else {
        const hasDuplicates = fields.some(
          (f) =>
            f.originalId === field.taskId && f.objectiveId === field.objectiveId
        );
        return hasDuplicates;
      }
    };

    const handleRemoveTask = (index) => {
      const tasks = getValues('tasks');
      const taskToRemove = tasks[index];

      dispatch(deleteTask(taskToRemove.id, taskToRemove.objectiveId));
      remove(index);
    };

    const addOption = (field, isOther) => {
      const tasks = getValues(`tasks`);

      const newOption = {
        text: isOther ? 'Other' : '',
        isOther: isOther,
      };

      const updatedTasks = tasks.map((task) => {
        if (field.taskId === task.id) {
          if (!!task.options.length) {
            return { ...task, options: [...task.options, newOption] };
          } else {
            return {
              ...task,
              options: [{ text: '', isOther: false }, { ...newOption }],
            };
          }
        } else {
          return task;
        }
      });

      setValue(`tasks`, updatedTasks);
    };

    const removeOption = (taskIndex, optionIndex) => {
      const tasks = getValues(`tasks`);

      const updatedOptions = [...tasks[taskIndex].options];

      updatedOptions.splice(optionIndex, 1);

      const updatedTasks = tasks.map((task, index) => {
        if (index === taskIndex) {
          return { ...task, options: updatedOptions };
        }
        return task;
      });

      setValue(`tasks`, updatedTasks);
    };

    const moveObjective = (task, index, direction) => {
      const tasks = getValues('tasks');
      const objectives = [...selectedObjectives];
      const foundTasks = tasks.filter(
        (t) => t.objectiveId === task.objectiveId
      );

      let swappableTasks = [];
      let firstSwipeTaskObjectiveId;
      let currentTaskObjectiveIndex = objectives.findIndex(
        (obj) => obj.id === task.objectiveId
      );

      if (direction === 'down') {
        const firstSwipeTask = tasks[foundTasks.length + index];
        firstSwipeTaskObjectiveId = firstSwipeTask.objectiveId;
        swappableTasks = tasks.filter(
          (t) => t.objectiveId === firstSwipeTaskObjectiveId
        );

        setValue('tasks', [
          ...tasks.slice(0, index),
          ...swappableTasks,
          ...foundTasks,
          ...tasks.slice(
            index + foundTasks.length + swappableTasks.length,
            tasks.length
          ),
        ]);
        let swappableObjectiveIndex = objectives.findIndex(
          (obj) => obj.id === firstSwipeTaskObjectiveId
        );
        [
          objectives[currentTaskObjectiveIndex],
          objectives[swappableObjectiveIndex],
        ] = [
          objectives[swappableObjectiveIndex],
          objectives[currentTaskObjectiveIndex],
        ];
      } else {
        const foundTaskObjectives = tasks[index - 1];
        firstSwipeTaskObjectiveId = foundTaskObjectives.objectiveId;
        swappableTasks = tasks.filter(
          (t) => t.objectiveId === firstSwipeTaskObjectiveId
        );

        setValue('tasks', [
          ...tasks.slice(
            0,
            tasks.length -
              foundTasks.length -
              swappableTasks.length -
              (tasks.length - index - foundTasks.length)
          ),
          ...foundTasks,
          ...swappableTasks,
          ...tasks.slice(index + foundTasks.length, tasks.length),
        ]);

        let swappableObjectiveIndex = objectives.findIndex(
          (obj) => obj.id === firstSwipeTaskObjectiveId
        );
        [
          objectives[currentTaskObjectiveIndex],
          objectives[swappableObjectiveIndex],
        ] = [
          objectives[swappableObjectiveIndex],
          objectives[currentTaskObjectiveIndex],
        ];
      }

      setSelectedObjectives(objectives);
    };

    const selectedTasks = useMemo(() => {
      const retTasks = [];

      for (const objective of selectedObjectives) {
        if ((objective.id + '').includes('/')) {
          let objectiveId = +objective.id.split('/')[0];
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objectiveId
          );
          retTasks.push(
            ...objectiveTasks.map((task) => ({
              ...task,
              objectiveId: objective.id,
            }))
          );
        } else {
          const objectiveTasks = tasks.filter(
            (task) => task.objectiveId === objective.id
          );
          retTasks.push(...objectiveTasks);
        }
      }

      return retTasks;
    }, [selectedObjectives, tasks]);

    const duplicateTask = (index) => {
      const tasks = getValues('tasks');
      insert(index + 1, tasks[index]);
      const newId = Date.now();

      dispatch(addDuplicateTask(tasks[index], newId));
    };

    const duplicateObjective = (objectiveId) => {
      const tasks = getValues('tasks');
      let objective = {
        ...selectedObjectives.find((obj) => obj.id === objectiveId),
      };

      const tasksByObjective = tasks.filter(
        (task) => task.objectiveId === objectiveId
      );

      const newObjectiveId = Date.now();
      objective.id = newObjectiveId;

      for (const task of tasksByObjective) {
        task.objectiveId = newObjectiveId;
        const newId = Date.now();
        dispatch(addDuplicateTask(task, newId));
      }
      setSelectedObjectives([...selectedObjectives, objective]);
    };

    const removeObjective = (objectiveId) => {
      setSelectedObjectives(
        selectedObjectives.filter((obj) => obj.id !== objectiveId)
      );
    };

    const canRemoveObjective = (objectiveHeadline) => {
      const filteredTasks = selectedObjectives.filter(
        (task) => task.headline === objectiveHeadline
      );
      return filteredTasks.length > 1;
    };

    useEffect(() => {
      if (!!taskDescriptions.length) {
        let updatedTasks = selectedTasks.map((task, index) => {
          const descriptionToUpdate = taskDescriptions.find(
            (td) => td.id === `${task.objectiveId}-${task.id}`
          );
          let pv;
          if (task.originalId) {
            pv = platformAction.find((p) => p.taskId === task.originalId);
          } else {
            pv = platformAction.find((p) => p.taskId === task.id);
          }

          const formTasks = getValues('tasks');

          const taskWithDefaults = {
            ...task,
            pv: pv,
            taskId: task.id,
            inPersonTask: inPerson,
            description: formTasks[index]?.description
              ? formTasks[index]?.description
              : descriptionToUpdate
              ? descriptionToUpdate.description
              : task.description,
          };

          if (pv && !!pv.url_required) {
            taskWithDefaults.url = formTasks[index]?.url
              ? formTasks[index].url
              : task.url || '';
          }
          if (!!+pv.hashtags_required) {
            taskWithDefaults.hashtags = formTasks[index]?.hashtags
              ? formTasks[index].hashtags
              : task.hashtags || [];
          }
          if (!!+pv.mentions_required) {
            taskWithDefaults.mentions = formTasks[index]?.mentions
              ? formTasks[index].mentions
              : task.mentions || '';
          }
          if (
            (pv.platform_action.toLowerCase() ===
              CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
              ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(
                pv.platform_item
              )) ||
            ['SURVEY-MULTIPLE', 'SURVEY-SINGLE'].includes(
              pv.basic_verification_method
            )
          ) {
            taskWithDefaults.options = formTasks[index]?.options
              ? formTasks[index].options
              : taskWithDefaults.options
              ? taskWithDefaults.options
              : [{ text: '', isOther: false }];
          }

          return taskWithDefaults;
        });

        if (Object.keys(tasksInfo).length > 0) {
          Object.values(tasksInfo).forEach((i) => {
            if (updatedTasks.find((t) => t.taskId === i.taskId)) {
              const index = updatedTasks.findIndex(
                (t) => t.taskId === i.taskId
              );
              updatedTasks[index] = i;
            }
          });
        }

        setValue('tasks', updatedTasks);
      } else {
        const updatedTasks = selectedTasks.map((task, index) => {
          let pv;
          let importedTask;
          if (task.originalId) {
            pv = platformAction.find((p) => p.taskId === task.originalId);
            if (importedCampaign) {
              importedTask = importedCampaign.tasks.find(
                (t) => t.id === task.originalId
              );
            }
          } else {
            pv = platformAction.find((p) => p.taskId === task.id);
            if (importedCampaign) {
              importedTask = importedCampaign.tasks.find(
                (t) => t.id === task.id
              );
            }
          }
          let newTask = { ...task, inPersonTask: inPerson };
          const formTasks = getValues('tasks');

          if (pv) {
            if (!!pv.url_required) {
              let taskUrl = task.url || '';
              if (importedTask?.url) {
                taskUrl = importedTask.url;
              }
              if (formTasks[index]?.url) {
                taskUrl = formTasks[index].url;
              }
              newTask.url = taskUrl;
            }
            if (!!+pv.hashtags_required) {
              let taskHashtags = task.hashtags || [];
              if (importedTask?.hashtags) {
                taskHashtags = importedTask.hashtags;
              }
              if (formTasks[index]?.hashtags) {
                taskHashtags = formTasks[index].hashtags;
              }
              newTask.hashtags = taskHashtags;
            }
            if (!!+pv.mentions_required) {
              let taskMentions = task.mentions || '';
              if (importedTask?.mentions) {
                taskMentions = importedTask.mentions;
              }
              if (formTasks[index]?.mentions) {
                taskMentions = formTasks[index].mentions;
              }
              newTask.mentions = taskMentions;
            }
            if (
              (pv.platform_action.toLowerCase() ===
                CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
                ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(
                  pv.platform_item
                )) ||
              ['SURVEY-MULTIPLE', 'SURVEY-SINGLE'].includes(
                pv.basic_verification_method
              )
            ) {
              let taskOptions = task.options || [{ text: '', isOther: false }];
              if (importedTask?.options) {
                taskOptions = importedTask.options;
              }
              if (formTasks[index]?.options) {
                taskOptions = formTasks[index].options;
              }
              newTask.options = taskOptions;
            }
          }

          let taskDescription = newTask?.description
            ? newTask.description
            : newTask.descriptions[0].label;

          if (importedTask?.description) {
            taskDescription = importedTask?.description;
          }
          if (formTasks[index]?.description) {
            taskDescription = formTasks[index].description;
          }

          if (Object.keys(tasksInfo).length > 0) {
            const savedTask = Object.values(tasksInfo).find(
              (i) => i.taskId === task.id
            );

            if (savedTask) {
              return savedTask;
            }
          }

          return {
            ...newTask,
            pv: pv,
            taskId: task.id,
            description: taskDescription,
          };
        });

        setValue('tasks', updatedTasks);
      }
    }, [platformAction, selectedTasks, taskDescriptions, setValue, inPerson]);

    useEffect(() => {
      if (socialAccountSuggestions && Object.keys(tasksInfo).length === 0) {
        let tasksArray = [];
        const tasks = getValues('tasks');
        for (const task of tasks) {
          let pv;
          if (task.originalId) {
            pv = platformAction.find((p) => p.taskId === task.originalId);
          } else {
            pv = platformAction.find((p) => p.taskId === task.id);
          }

          let newTask = { ...task, pv: pv };
          if (!!pv.url_required) {
            const socialUrls = socialAccountSuggestions.socialMediaUrls;
            // const userURL = currentUserInput.url || '';

            if (pv.platform === 'FB' && socialUrls.facebook) {
              newTask.url = socialUrls.facebook;
              newTask.platform = pv.platform;
              //   newTask.isLinkValid = socialUrls.facebook.isValid;
            }

            if (pv.platform === 'IG' && socialUrls.instagram) {
              newTask.url = socialUrls.instagram;
              newTask.platform = pv.platform;
              //   newTask.isLinkValid = socialUrls.instagram.isValid;
            }

            if (pv.platform === 'X' && socialUrls.twitter) {
              newTask.url = socialUrls.twitter;
              newTask.platform = pv.platform;
              //   newTask.isLinkValid = socialUrls.twitter.isValid;
            }

            if (pv.platform === 'TT' && socialUrls.tiktok) {
              newTask.url = socialUrls.tiktok;
              newTask.platform = pv.platform;
              //   newTask.isLinkValid = socialUrls.tiktok.isValid;
            }

            if (pv.platform === 'LI' && socialUrls.linkedIn) {
              newTask.url = socialUrls.linkedIn;
              newTask.platform = pv.platform;
              //   newTask.isLinkValid = socialUrls.linkedIn.isValid;
            }

            if (pv.platform === 'HTTP3' && brandWebsite) {
              newTask.url = brandWebsite;
              newTask.platform = pv.platform;
            }
          }

          if (!!+pv.hashtags_required) {
            // const userHashtags = currentUserInput.hashtags || '';
            const newHashtags = socialAccountSuggestions.hashtags.map((h) => {
              if (!h.startsWith('#')) {
                return `#${h}`;
              } else {
                return h;
              }
            });
            // const combinedHashtags = [userHashtags, newHashtags]
            //   .filter(Boolean)
            //   .join(', ');

            newTask.hashtags = newHashtags;
            newTask.platform = pv.platform;
          }

          if (!!+pv.mentions_required) {
            // const userMentions = currentUserInput.mentions || '';
            const newMentions = socialAccountSuggestions.mentions.join(', ');
            // const combinedMentions = [userMentions, newMentions]
            //   .filter(Boolean)
            //   .join(', ');

            newTask.mentions = newMentions;
            newTask.platform = pv.platform;
          }

          tasksArray.push(newTask);
        }
        setValue('tasks', tasksArray);
      }
    }, [
      tasksInfo,
      taskDescriptions,
      platformAction,
      socialAccountSuggestions,
      setValue,
      getValues,
    ]);

    return (
      <div className={styles.contentContainer}>
        <div className={styles.container}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => {
              const tasks = fields.filter(
                (task) => task.objectiveId === field.objectiveId
              );

              const firstTaskOfObjective = fields.find(
                (t) =>
                  t.taskId === tasks[0].taskId &&
                  t.objectiveId === tasks[0].objectiveId
              );

              const isFirstTaskOfObjective =
                field.id === firstTaskOfObjective.id;

              let objectives = [];

              for (const task of fields) {
                if (!objectives.find((obj) => obj === task.objectiveId)) {
                  objectives.push(task.objectiveId);
                }
              }

              const isFirstObjective =
                objectives[0] === firstTaskOfObjective.objectiveId;

              const isLastObjective =
                objectives[objectives.length - 1] ===
                firstTaskOfObjective.objectiveId;

              return (
                <div className={styles.tableBodyRow} key={field.id}>
                  <div
                    className={styles.sortContainer}
                    style={{
                      marginTop: isFirstTaskOfObjective ? '60px' : '0',
                    }}
                  >
                    <div
                      style={{
                        transform: 'rotate(180deg)',
                        opacity:
                          fields[index - 1]?.objectiveId === field.objectiveId
                            ? 1
                            : 0.3,
                      }}
                      onClick={() => {
                        if (
                          fields[index - 1]?.objectiveId === field.objectiveId
                        ) {
                          move(index, index - 1);
                        }
                      }}
                    >
                      <ArrouwDownIcon color="gray" width={14} height={14} />
                    </div>
                    <div
                      style={{
                        opacity:
                          fields[index + 1]?.objectiveId === field.objectiveId
                            ? 1
                            : 0.3,
                      }}
                      onClick={() => {
                        if (
                          fields[index + 1]?.objectiveId === field.objectiveId
                        ) {
                          move(index, index + 1);
                        }
                      }}
                    >
                      <ArrouwDownIcon color="gray" width={14} height={14} />
                    </div>
                  </div>
                  <div className={styles.descriptionContainer}>
                    {isFirstTaskOfObjective && (
                      <div className={styles.objectiveContainer}>
                        <div
                          className={styles.sortContainer}
                          style={{
                            marginLeft: '-42px',
                          }}
                        >
                          <div
                            style={{
                              transform: 'rotate(180deg)',
                              opacity: isFirstObjective ? 0.3 : 1,
                            }}
                            onClick={() => {
                              if (!isFirstObjective) {
                                moveObjective(field, index);
                              }
                            }}
                          >
                            <ArrouwDownIcon
                              color="#338891"
                              width={14}
                              height={14}
                            />
                          </div>
                          <div
                            style={{
                              opacity: isLastObjective ? 0.3 : 1,
                            }}
                            onClick={() => {
                              if (!isLastObjective) {
                                moveObjective(field, index, 'down');
                              }
                            }}
                          >
                            <ArrouwDownIcon
                              color="#338891"
                              width={14}
                              height={14}
                            />
                          </div>
                        </div>
                        <p className={styles.objectiveHeadline}>OBJECTIVE:</p>
                        <p className={styles.objectiveHeadline2}>
                          {field.objectiveHeadline}
                        </p>
                        {canRemoveObjective(field.objectiveHeadline) ? (
                          <div
                            className={styles.objectiveRemove}
                            onClick={() => removeObjective(field.objectiveId)}
                          >
                            -
                          </div>
                        ) : (
                          <div className={styles.emptyDiv} />
                        )}
                        <div
                          className={styles.objectiveDuplicate}
                          onClick={() => duplicateObjective(field.objectiveId)}
                        >
                          +
                        </div>
                      </div>
                    )}
                    {field.pv.platform_action.toLowerCase() ===
                      CAMPAIGN_TASKS_CATEGORIES.SURVEY ||
                    [
                      'SURVEY-LONG',
                      'SURVEY-SHORT',
                      'SURVEY-MULTIPLE',
                      'SURVEY-SINGLE',
                    ].includes(field.pv.basic_verification_method) ? (
                      <>
                        <div className={styles.tasksDescriptionContainer}>
                          <p className={styles.taskText}>
                            ACTIVITY {index + 1}:
                          </p>
                          <div className={styles.actionsContainer}>
                            <Tooltip
                              className={styles.tooltip}
                              label={`task-${field.taskId}`}
                              customContent={<p>{field.name}</p>}
                            />
                          </div>
                        </div>
                        {/* <TaskInput
                          pv={field.pv}
                          task={field}
                          {...register(`tasks[${index}].description`)}
                          //   tasksInfo={tasksInfo}
                          
                          onRegenerateClick={onRegenerateClick}
                          width={window.innerWidth}
                          loading={
                            loadingStates[`${field.objectiveId}-${field.id}`]
                          }
                        /> */}
                        <div
                          className={styles.surveyContainer}
                          style={{
                            paddingRight:
                              field.descriptions.length <= 1
                                ? window.innerWidth <= 950
                                  ? '20px'
                                  : window.innerWidth <= 630
                                  ? '10px'
                                  : '130px'
                                : '0px',
                          }}
                        >
                          <p className={styles.surveyText}>Input: </p>
                          <Input
                            className={styles.surveyInput}
                            type={'textarea'}
                            value={descriptionValues[index]}
                            maxLength={100}
                            {...register(`tasks[${index}].description`)}
                            redBorder
                            rightIcon={
                              <DeleteBlackIcon width={20} height={20} />
                            }
                            error={errors.tasks?.[index]?.description?.message}
                            handleClearInput={() =>
                              handleClearInputForField(
                                `tasks[${index}].description`
                              )
                            }
                          />
                          {field.descriptions.length > 1 &&
                            (loadingStates[
                              `${field.objectiveId}-${field.id}`
                            ] ? (
                              <LoadingSpinner className={styles.taskSpinner} />
                            ) : (
                              <div
                                className={styles.regenerateContainer}
                                onClick={() => {
                                  onRegenerateClick(
                                    `${field.objectiveId}-${field.taskId}`,
                                    field.descriptions,
                                    field.id,
                                    index
                                  );
                                }}
                              >
                                <SyncIcon
                                  color="white"
                                  width={18}
                                  height={18}
                                />
                                <p className={styles.pbutton}>regenerate</p>
                              </div>
                            ))}
                        </div>
                      </>
                    ) : (
                      <div className={styles.tasksContainer}>
                        <p className={styles.taskText}>ACTIVITY {index + 1}:</p>
                        <div className={styles.actionsContainer}>
                          <Tooltip
                            className={styles.tooltip}
                            label={`task-${field.taskId}`}
                            customContent={<p>{field.name}</p>}
                          />
                        </div>
                        <div
                          className={styles.relativeDescriptionInputContainer}
                        >
                          <Input
                            className={styles.duplicatedInput}
                            type={'textarea'}
                            {...register(`tasks[${index}].description`)}
                            redBorder
                            error={errors.tasks?.[index]?.description?.message}
                            handleClearInput={() =>
                              handleClearInputForField(
                                `tasks[${index}].description`
                              )
                            }
                            value={descriptionValues[index]}
                            maxLength={500}
                            rightIcon={
                              <DeleteBlackIcon width={20} height={20} />
                            }
                          />
                        </div>

                        {field.pv.platform_action.toLowerCase() !==
                          CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
                          ![
                            'SURVEY-LONG',
                            'SURVEY-SHORT',
                            'SURVEY-MULTIPLE',
                            'SURVEY-SINGLE',
                          ].includes(field.pv.basic_verification_method) &&
                          (loadingStates[`${field.objectiveId}-${field.id}`] ? (
                            <LoadingSpinner className={styles.taskSpinner} />
                          ) : (
                            <div
                              className={styles.regenerateButton}
                              onClick={() => {
                                onRegenerateClick(
                                  `${field.objectiveId}-${field.taskId}`,
                                  field.descriptions,
                                  field.id,
                                  index
                                );
                              }}
                            >
                              <SyncIcon color="white" width={18} height={18} />
                              <p className={styles.pbutton}>regenerate</p>
                            </div>
                          ))}
                      </div>
                    )}
                    <div className={styles.userActionContainer}>
                      <div className={styles.timeContainer}>
                        <p className={styles.time}>Time:</p>
                        <p className={styles.timeText}>{field.time} minutes</p>
                      </div>
                      {!!field.pv.url_required && (
                        <>
                          <div className={styles.urlInputContainer}>
                            <p className={styles.inputLabel}>
                              {!!field.pv.url_required &&
                                (PLATFORM_MAPPER[field.pv.platform] ===
                                  'EyCrowd' ||
                                PLATFORM_MAPPER[field.pv.platform] === 'HTTP3'
                                  ? 'URL:'
                                  : `${
                                      PLATFORM_MAPPER[field.pv.platform]
                                    } URL: *`)}
                            </p>

                            <div className={styles.relativeInputContainer}>
                              <Input
                                placeholder={`https://`}
                                {...register(`tasks[${index}].url`)}
                                rightIcon={
                                  <DeleteBlackIcon width={20} height={20} />
                                }
                                value={urlValues[index]}
                                redBorder
                                error={errors.tasks?.[index]?.url?.message}
                                handleClearInput={() => {
                                  handleClearInputForField(
                                    `tasks[${index}].url`
                                  );
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  let newValue;

                                  if (value) {
                                    if (!value.startsWith('https://')) {
                                      newValue = `https://${value}`;
                                    } else {
                                      newValue = value;
                                    }
                                    setValue(
                                      `tasks[${index}].url`,
                                      newValue.trim()
                                    );
                                  }
                                }}
                              />
                            </div>

                            {urlValues[index] &&
                              !getSocialAccountSuggestionsPending && (
                                <div
                                  className={styles.visitContainer}
                                  onClick={() => {
                                    const url = urlValues[index]
                                      ? urlValues[index]
                                      : '';
                                    if (url !== '') {
                                      window.open(url, '_blank');
                                    }
                                  }}
                                >
                                  <VisitIcon
                                    color="white"
                                    width={18}
                                    height={18}
                                  />
                                  <p>visit</p>
                                </div>
                              )}
                            {getSocialAccountSuggestionsPending ? (
                              <LoadingSpinner
                                margin
                                className={styles.spinner}
                              />
                            ) : (
                              <div
                                className={styles.spinnerDiv}
                                style={{ marginBottom: 24 }}
                              />
                            )}
                          </div>
                        </>
                      )}

                      {!!+field.pv.hashtags_required && (
                        <div className={styles.hashtagContainer}>
                          <p className={styles.hashtagLabel}>Hashtags:</p>

                          {!!+field.pv.hashtags_required && (
                            <div className={styles.relativeInputContainer}>
                              <Input
                                placeholder="(optional) #hashtag1 #hashtag2 ..."
                                hideErrorMessage
                                {...register(`tasks[${index}].hashtags`)}
                                rightIcon={
                                  <DeleteBlackIcon width={20} height={20} />
                                }
                                value={hashtagValues[index]}
                                handleClearInput={() => {
                                  handleClearInputForField(
                                    `tasks[${index}].hashtags`
                                  );
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  if (value.trim()) {
                                    const values = value.split(' ');
                                    const newValues = [];

                                    for (const v of values) {
                                      if (!v.startsWith('#') && v.trim()) {
                                        newValues.push(`#${v}`);
                                      } else {
                                        newValues.push(v);
                                      }
                                    }
                                    setValue(
                                      `tasks[${index}].hashtags`,
                                      newValues.join(' ')
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {hashtagValues[index] &&
                            !getSocialAccountSuggestionsPending && (
                              <div
                                className={styles.hashtagsContainer}
                                onClick={() => {
                                  let url = '';
                                  const hashtags = hashtagValues[index]
                                    ? hashtagValues[index]
                                    : '';

                                  let firstHashtag;
                                  if (hashtags instanceof Array) {
                                    const hashtagArray = hashtags.map((tag) =>
                                      tag.trim()
                                    );
                                    firstHashtag = hashtagArray[0];
                                  } else {
                                    const hashtagArray = hashtags
                                      .split(',')
                                      .map((tag) => tag.trim());
                                    firstHashtag = hashtagArray[0];
                                  }

                                  const firstHashtagWithoutHash =
                                    firstHashtag.slice(1);
                                  if (field.pv.platform === 'FB') {
                                    url = `https://www.facebook.com/hashtag/${firstHashtagWithoutHash}`;
                                  }

                                  if (field.pv.platform === 'IG') {
                                    url = `https://www.instagram.com/explore/tags/${firstHashtagWithoutHash}`;
                                  }
                                  if (field.pv.platform === 'X') {
                                    url = `https://twitter.com/search?q=%23${firstHashtagWithoutHash}`;
                                  }

                                  if (field.pv.platform === 'TT') {
                                    url = `https://www.tiktok.com/search?q=${firstHashtagWithoutHash}`;
                                  }

                                  if (field.pv.platform === 'LI') {
                                    url = `https://www.linkedin.com/feed/hashtag/${firstHashtagWithoutHash}`;
                                  }

                                  if (url !== '') {
                                    window.open(url, '_blank');
                                  }
                                }}
                              >
                                <SearchIcon
                                  color="white"
                                  width={18}
                                  height={18}
                                />
                                <p>search</p>
                              </div>
                            )}
                          {getSocialAccountSuggestionsPending ? (
                            <LoadingSpinner className={styles.spinner} />
                          ) : (
                            <div className={styles.spinnerDiv} />
                          )}
                        </div>
                      )}

                      {!!+field.pv.mentions_required && (
                        <div className={styles.hashtagContainer}>
                          <p className={styles.hashtagLabel}>Mentions:</p>

                          <div className={styles.relativeInputContainer}>
                            <Input
                              placeholder="(optional) @mention1 @mention2 ..."
                              hideErrorMessage
                              {...register(`tasks[${index}].mentions`)}
                              rightIcon={
                                <DeleteBlackIcon width={20} height={20} />
                              }
                              value={mentionValues[index]}
                              handleClearInput={() => {
                                handleClearInputForField(
                                  `tasks[${index}].mentions`
                                );
                              }}
                              onBlur={(e) => {
                                const value = e.target.value;
                                if (value.trim()) {
                                  const values = value.split(' ');
                                  const newValues = [];

                                  for (const v of values) {
                                    if (!v.startsWith('@') && v.trim()) {
                                      newValues.push(`@${v}`);
                                    } else {
                                      newValues.push(v);
                                    }
                                  }

                                  setValue(
                                    `tasks[${index}].mentions`,
                                    newValues.join(' ')
                                  );
                                }
                              }}
                            />
                          </div>

                          {mentionValues[index] &&
                            !getSocialAccountSuggestionsPending && (
                              <div
                                className={styles.hashtagsContainer}
                                onClick={() => {
                                  let url = '';
                                  const mentions = mentionValues[index]
                                    ? mentionValues[index]
                                    : '';
                                  const mentionArray = mentions
                                    .split(',')
                                    .map((mention) => mention.trim());
                                  const firstMention = mentionArray[0];

                                  const firstMentionWithoutAt =
                                    firstMention.slice(1);
                                  if (field.pv.platform === 'FB') {
                                    url = `https://www.facebook.com/${firstMentionWithoutAt}`;
                                  }

                                  if (field.pv.platform === 'IG') {
                                    url = `https://www.instagram.com/${firstMentionWithoutAt}`;
                                  }
                                  if (field.pv.platform === 'X') {
                                    url = `https://twitter.com/${firstMentionWithoutAt}`;
                                  }

                                  if (field.pv.platform === 'TT') {
                                    url = `https://www.tiktok.com/@${firstMentionWithoutAt}`;
                                  }

                                  if (field.pv.platform === 'LI') {
                                    url = `https://www.linkedin.com/${firstMentionWithoutAt}`;
                                  }

                                  if (url !== '') {
                                    window.open(url, '_blank');
                                  }
                                }}
                              >
                                <SearchIcon
                                  color="white"
                                  width={18}
                                  height={18}
                                />
                                <p>search</p>
                              </div>
                            )}
                          {getSocialAccountSuggestionsPending ? (
                            <LoadingSpinner className={styles.spinner} />
                          ) : (
                            <div className={styles.spinnerDiv} />
                          )}
                        </div>
                      )}

                      {inPerson && (
                        <div style={{ display: 'flex' }}>
                          <p className={styles.hashtagLabel}>In-Person:</p>

                          <Controller
                            name={`tasks[${index}].inPersonTask`}
                            control={control}
                            render={({ field }) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '20px',
                                  }}
                                >
                                  <RadioButton
                                    title="yes: geofenced"
                                    checked={field.value}
                                    onClick={() => field.onChange(true)}
                                  />
                                  <RadioButton
                                    title="no: anywhere"
                                    checked={!field.value}
                                    onClick={() => field.onChange(false)}
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      )}

                      {((field.pv.platform_action.toLowerCase() ===
                        CAMPAIGN_TASKS_CATEGORIES.SURVEY &&
                        ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'].includes(
                          field.pv.platform_item
                        )) ||
                        ['SURVEY-MULTIPLE', 'SURVEY-SINGLE'].includes(
                          field.pv.basic_verification_method
                        )) && (
                        <div className={styles.textareasRow}>
                          <div className={styles.selectionTextContainer}>
                            <p className={styles.changeableText}>
                              {field.pv.platform_item === 'MULTIPLE_CHOICE'
                                ? 'Multiple'
                                : 'Single'}
                            </p>
                            <p className={styles.selectionText}>Selection:</p>
                          </div>

                          <div className={styles.inputs}>
                            {field?.options?.length > 1 ? (
                              field?.options.map((op, ind) => {
                                return (
                                  <div
                                    className={styles.inputContainer}
                                    key={ind}
                                  >
                                    <Input
                                      placeholder={`option${ind + 1}`}
                                      value={optionTextValues[index][ind]}
                                      disabled={op?.isOther}
                                      redBorder
                                      error={
                                        errors.tasks?.[index]?.options?.[ind]
                                          ?.text?.message
                                      }
                                      required
                                      maxLength={30}
                                      {...register(
                                        `tasks[${index}].options[${ind}].text`
                                      )}
                                    />
                                    <CloseIcon
                                      onClick={() => removeOption(index, ind)}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <div className={styles.inputContainer}>
                                <Input
                                  placeholder={`option1`}
                                  required
                                  redBorder
                                  error={
                                    errors.tasks?.[index]?.options?.message ||
                                    errors.tasks?.[index]?.options?.[0]?.text
                                      ?.message
                                  }
                                  maxLength={30}
                                  {...register(
                                    `tasks[${index}].options[${0}].text`
                                  )}
                                  value={optionTextValues[index][0]}
                                />
                              </div>
                            )}
                            <div className={styles.addOptions}>
                              <p
                                className={classNames(
                                  styles.addOption,
                                  field?.options?.length > 5 &&
                                    styles.addOptionDisabled
                                )}
                                onClick={() => addOption(field, false)}
                              >
                                + add option
                              </p>
                              <p>or</p>
                              <p
                                className={classNames(
                                  styles.addOther,
                                  (field?.options?.length > 5 ||
                                    field?.options?.some((o) => o.isOther)) &&
                                    styles.addOptionDisabled
                                )}
                                onClick={() => addOption(field, true)}
                              >
                                + add "other"
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className={styles.manageContainer}>
                        <p className={styles.manageText}>Manage: </p>
                        <div className={styles.manageButtonsContainer}>
                          <div
                            className={styles.duplicateButton}
                            onClick={() => duplicateTask(index, field.id)}
                          >
                            <DuplicateIcon
                              color="black"
                              width={18}
                              height={18}
                            />
                            <p className={styles.duplicateText}>
                              duplicate activity
                            </p>
                          </div>
                          {canDeleteTask(field) && (
                            <div
                              className={styles.deleteButton}
                              onClick={() => handleRemoveTask(index)}
                            >
                              <DeleteTaskIcon
                                color="black"
                                width={18}
                                height={18}
                              />
                              <p className={styles.duplicateText}>
                                delete activity
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
        </div>
      </div>
    );
  }
);

export default TaskManager;
